<template>
  <Container class="container">
    <div class="d-flex justify-content-between">
      <h1>Proxy Maintenance</h1>
      <div class="mr-2 d-inline-flex flex-column">
        <label><b>Show Expired</b></label>
        <DxSwitch v-model="viewExpired" :key="viewExpired" />
      </div>
    </div>
    <DxGridWithSearch
      :columns="columns"
      :dataSource="dataSource"
      gridName="Proxy Maintenance"
      :toolbar="toolbar"
      @initialized="initGrid"
    >
      <template v-slot:actionsTemplate="{ data: { data, rowIndex } }">
        <div
          class="d-flex justify-content-center align-items-center"
          :data-testid="'actions-col-' + rowIndex"
        >
          <IconButton
            v-if="canEditProxy(data)"
            v-tooltip.right="'Edit proxy.'"
            class="btn text-primary pointer p-0 px-1 mr-1"
            @click="editProxy(data)"
            icon="pen-alt"
          />
        </div>
      </template>
      <template v-slot:extraActions>
        <AddButton v-if="permissions.ProxyCreateEdit" @click="addProxy" />
      </template>
    </DxGridWithSearch>
    <Modal :status="isModalOpen" @close="closeModal">
      <form class="adding-popup" @submit.prevent="handleSubmit">
        <h2>{{ proxy.id ? "Edit" : "Add" }} Proxy Configuration</h2>
        <SelectInput
          v-focus
          label="Granting Pathologist"
          :items="pathologists"
          name="grantingPathologist"
          id="grantingPathologist"
          v-model="proxy.granterUserId"
          searchExpr="displayName"
          displayExpr="displayName"
          :valueExpr="pathologistValue"
          :validator="$v.proxy.granterUserId"
          :disabled="!permissions.ProxyAdmin"
        />
        <SelectInput
          label="Proxy User"
          name="proxyUser"
          id="proxyUser"
          :dataSource="userDataSource"
          v-model="proxy.granteeUserId"
          :displayExpr="displayName"
          searchExpr="displayName"
          :valueExpr="proxyValue"
          :validator="$v.proxy.granteeUserId"
        />
        <div class="row">
          <DatePicker
            class="col"
            label="Effective On"
            v-model="proxy.effectiveOn"
            :max="proxy.expiryOn"
            :validator="$v.proxy.effectiveOn"
          />
          <DatePicker
            class="col"
            label="Expires On"
            v-model="proxy.expiryOn"
            :min="proxy.effectiveOn"
          />
        </div>
        <Checkbox
          v-model="proxy.showProxyOnReport"
          id="showProxyOnReport"
          label="Proxy Signature On Report"
        />
        <div class="my-2 d-flex justify-content-end">
          <loader v-if="isLoading" size="small" class="mx-1" />
          <button @click="closeModal" type="button" class="btn btn-danger">Cancel</button>
          <button :disabled="isLoading || $v.$invalid" type="submit" class="btn btn-primary mx-2">
            Submit
          </button>
        </div>
      </form>
    </Modal>
  </Container>
</template>

<script>
import { DropdownApi, ProxyApi, UsersApi } from "@/services";
import DataSource from "devextreme/data/data_source";
import AddButton from "./common/AddButton.vue";
import Container from "./common/Container.vue";
import DxGridWithSearch from "./common/DxGridWithSearch.vue";
import Modal from "./common/Modal.vue";
import moment from "moment";
import SelectInput from "./common/SelectInput.vue";
import DatePicker from "./common/DatePicker.vue";
import Checkbox from "./common/Checkbox.vue";
import IconButton from "./common/IconButton.vue";
import { cloneDeep } from "lodash";
import { DxSwitch } from "devextreme-vue";
import { isEffective } from "@/modules/helpers";
import { handleErrors } from "@/modules/handleErrors";
import { required } from "vuelidate/lib/validators";
import { mapGetters, mapState } from "vuex";

export default {
  metaInfo: {
    title: "Proxy Maintenance",
    titleTemplate: "IntelliPath - %s"
  },
  components: {
    DxGridWithSearch,
    Container,
    AddButton,
    Modal,
    SelectInput,
    DatePicker,
    Checkbox,
    IconButton,
    DxSwitch
  },
  data() {
    return {
      isLoading: false,
      isModalOpen: false,
      defaultProxy: {
        id: null,
        granterUserId: null,
        granteeUserId: "",
        effectiveOn: moment().format("yyyy-MM-DD"),
        expiryOn: null,
        showProxyOnReport: false
      },
      proxy: {},
      viewExpired: false,
      proxies: [],
      pathologists: []
    };
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser
    }),
    ...mapGetters(["permissions"]),
    columns() {
      return [
        {
          type: "buttons",
          cellTemplate: "actionsTemplate",
          caption: "Action",
          width: "100px"
        },
        {
          dataField: "granterUserId",
          caption: "Granting Pathologist",
          dataType: "string",
          lookup: {
            dataSource: DropdownApi.searchPathologists,
            displayExpr: "displayName",
            valueExpr: "userId"
          },
          allowSearch: true,
          allowHeaderFiltering: true
        },
        {
          dataField: "granteeUserId",
          caption: "Proxy User",
          lookup: {
            dataSource: UsersApi.labUsersStore,
            displayExpr: data => this.proxyDisplayName(data),
            valueExpr: "id"
          },
          dataType: "string",
          allowSearch: true,
          allowHeaderFiltering: true
        },
        {
          dataField: "effectiveOn",
          allowReordering: true,
          dataType: "date",
          allowHeaderFiltering: false
        },
        {
          dataField: "expiryOn",
          allowReordering: true,
          dataType: "date",
          allowHeaderFiltering: false
        },
        {
          dataField: "showProxyOnReport",
          dataType: "boolean",
          lookup: {
            displayExpr: "text",
            valueExpr: "value",
            dataSource: [
              {
                value: true,
                text: "Yes"
              },
              {
                value: false,
                text: "No"
              }
            ]
          },
          allowSearch: false
        }
      ];
    },
    dataSource() {
      return new DataSource({
        store: this.viewExpired ? this.proxies : this.proxies.filter(e => isEffective(e))
      });
    },
    toolbar() {
      return {
        items: [
          {
            location: "after",
            template: "extraActions",
            visible: true
          }
        ]
      };
    },
    userDataSource() {
      return new DataSource({
        store: DropdownApi.proxyUserOptionsStore,
        sort: "displayName",
      });
    }
  },
  mounted() {
    this.getProxyList();
    DropdownApi.searchPathologists
      .load({ sort: { selector: "displayName", desc: false } })
      .then(res => {
        this.pathologists = res.map(e => {
          return { id: e.userId, displayName: e.displayName };
        });
      });
  },
  validations() {
    return {
      proxy: {
        effectiveOn: {
          required
        },
        granteeUserId: {
          required,
          sameUser: value => value !== this.proxy.granterUserId,
          isProxyUnique: value => {
            const existingProxy = this.proxies.find(
              e => e.granteeUserId === value && e.granterUserId === this.proxy.granterUserId
            );
            if (existingProxy && existingProxy.id !== this.proxy?.id) {
              return false;
            }
            return true;
          }
        },
        granterUserId: {
          required
        }
      }
    };
  },
  watch: {
    "proxy.granteeUserId": {
      immediate: true,
      handler(nv) {
        if (this.proxy?.id) {
          return;
        }
        if (nv && this.pathologists.find(e => e?.id === nv)) {
          this.proxy.showProxyOnReport = true;
        } else {
          this.proxy.showProxyOnReport = false;
        }
      }
    }
  },
  methods: {
    initGrid({ component }) {
      this.grid = component;
    },
    proxyDisplayName(data) {
      if (!data) {
        return "";
      }
      return `${data.displayName} (${data.userName})`;
    },
    addProxy() {
      this.isModalOpen = true;
      this.proxy = cloneDeep(this.defaultProxy);
      if (!this.permissions.ProxyAdmin) {
        this.proxy.granterUserId = this.currentUser.id;
      }
    },
    closeModal() {
      this.isModalOpen = false;
      this.proxy = this.defaultProxy;
    },
    async handleSubmit() {
      this.isLoading = true;
      try {
        if (this.proxy.id) {
          await ProxyApi.editProxy(this.proxy);
          window.notify("Proxy updated.");
        } else {
          await ProxyApi.addProxy(this.proxy);
          window.notify("Proxy added.");
        }
        this.closeModal();
        this.getProxyList();
      } catch (error) {
        handleErrors(error);
      } finally {
        this.isLoading = false;
      }
    },
    editProxy(data) {
      this.isModalOpen = true;
      this.proxy = cloneDeep(data);
    },
    proxyValue(data) {
      // for some reason it keeps trying to conver the string ID to a number
      return data?.id;
    },
    pathologistValue(data) {
      // for some reason it keeps trying to conver the string ID to a number
      return data?.id;
    },
    canEditProxy(data) {
      if (this.permissions.ProxyAdmin) {
        return true;
      }
      if (!this.permissions.ProxyCreateEdit) {
        return false;
      }
      return data.granterUserId === this.currentUser.id;
    },
    getProxyList() {
      ProxyApi.searchProxies.load().then(res => {
        this.proxies = res;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  background: $white;
  border-radius: 3px;
  padding: 20px;
}
.adding-popup {
  width: 95%;
}
</style>
